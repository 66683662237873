import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'

import Landing from '../components/luxottica/Container'

const Luxottica = () => {
  return (
    <Router>
      <Landing path="/luxottica" />
    </Router>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Luxottica

import React, { useState } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { RouteProps } from 'react-router-dom'
import Select from 'react-select'

import LuxotticaLogo from '../../../static/images/luxottica/luxottica-logo.png'
import { ValidateEmail, ValidateEmailByLength } from '../../helpers'
import Logo from '../../images/logo.svg'
import { ApiService } from '../../services'
import InlineSpinner from '../common/InlineSpinner'
import SEO from '../seo'
import './luxottica.less'

type State = {
  email: string
  emailValid: boolean
  locationValid: boolean
  loading: boolean
}

type SelectedLocation = {
  value: string
  label: string
}

const LuxotticaContainer: React.FC<RouteProps<'/luxottica'>> = () => {
  const [state, setState] = useState<State>({
    email: '',
    emailValid: true,
    locationValid: true,
    loading: false,
  })

  const [selectedLocation, setLocation] = useState<SelectedLocation>({
    value: '',
    label: 'Choose Office Location',
  })

  const options = [
    {
      value: '420 5th Ave',
      label: '420 5th Ave',
    },
    {
      value: '1 W 37th St',
      label: '1 W 37th St',
    },
  ]

  const onLocationChangeHandler = (e: any) => {
    setLocation(e)
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.loading) return
    state.email = state.email.trim()
    const emailValid = ValidateEmail(state.email) && ValidateEmailByLength(state.email)
    const locationValid = selectedLocation.value !== ''

    if (emailValid && locationValid) {
      setState({ ...state, emailValid, loading: true })

      ApiService.createCorporateWaitlistApplicant({
        email: state.email,
        location: selectedLocation.value,
      }).then(() => {
        const button = document.getElementById('submitButton') as HTMLButtonElement
        if (button !== null) {
          button.textContent = 'Submitted!'
          button.disabled = true
        }
      })
    } else {
      setState({ ...state, emailValid, locationValid })
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const value = target.value
    setState({ ...state, [target.name]: value })
  }

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      maxWidth: '95%', // Set the maximum width for the dropdown menu
    }),
  }

  return (
    <>
      <SEO title="Luxottica" />
      <header className="header">
        <a href="/" className="header__logo">
          <img src={Logo} />
        </a>
      </header>
      <div className="corporate-wrapper">
        <div className="corporate__modal">
          <img src={LuxotticaLogo} />
          <div className="corporate__free-lunch">
            has gifted all NYC employees <br />
            <span>FREE lunch for a month</span>!
          </div>
          <div className="corporate__reserve-spot">YOU MUST RESERVE YOUR SPOT:</div>
          <div className="corporate__form">
            <form className="form" onSubmit={onSubmitHandler}>
              <input
                className={`form__input ${!state.emailValid && 'form__input--error'}`}
                type="text"
                name="email"
                value={state.email}
                placeholder="Email address"
                onChange={onChangeHandler}
              />
              {!state.emailValid && (
                <div className="form__error-message">
                  <FiAlertCircle /> {'Invalid email address'}
                </div>
              )}
              <div className="custom-select__wrapper">
                <Select
                  styles={customStyles}
                  classNamePrefix="custom-select"
                  isSearchable={false}
                  value={selectedLocation}
                  onChange={onLocationChangeHandler}
                  options={options}
                />
                {!state.emailValid && (
                  <div className="form__error-message">
                    <FiAlertCircle /> {'Please select a location'}
                  </div>
                )}
              </div>

              <button className="red-cta" type="submit" id="submitButton">
                {state.loading ? <InlineSpinner /> : 'SUBMIT'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default LuxotticaContainer
